/* tslint:disable */
/* eslint-disable */
/**
 * defaultTitle
 * defaultDescription
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    unitId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    plate?: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    fleetName?: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    nickname?: string;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    lat?: number;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    lon?: number;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    driverName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    dataDate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    locationDate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    sinceDataDate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    sinceLocationDate?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    address?: string;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    speed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    odometer?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    direction?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    locationType?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Location
     */
    online?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    blockEngineStatus?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    fleetId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    dvrUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Location
     */
    ignition?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    ignitionDate?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    imei?: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    accountingCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    ttsPlate?: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    idleDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    idleLimit?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    driverId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    driverMsisdn?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    status?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    canDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    fuelTankLevel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    odometerInMeters?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    wheelSpeed?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    fuelConsumedEver?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    handbrake?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    engineRPM?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    acceleratorPosition?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    engineCoolantTemperature?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    engineRuntimeEver?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    odometerDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    odometerValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    devicePowerDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    devicePowerValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    batteryVoltageDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    batteryVoltageValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    generatorDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    generatorValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    geoFenceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    geoFenceName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    deviceModel?: string;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    dayOdometer?: number;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    redirectedServer?: string;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    deviceId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    customerName?: string;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    customerId?: number | null;
}

/**
 * Check if a given object implements the Location interface.
 */
export function instanceOfLocation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LocationFromJSON(json: any): Location {
    return LocationFromJSONTyped(json, false);
}

export function LocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Location {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'unitId': !exists(json, 'unitId') ? undefined : json['unitId'],
        'plate': !exists(json, 'plate') ? undefined : json['plate'],
        'fleetName': !exists(json, 'fleetName') ? undefined : json['fleetName'],
        'nickname': !exists(json, 'nickname') ? undefined : json['nickname'],
        'lat': !exists(json, 'lat') ? undefined : json['lat'],
        'lon': !exists(json, 'lon') ? undefined : json['lon'],
        'driverName': !exists(json, 'driverName') ? undefined : json['driverName'],
        'dataDate': !exists(json, 'dataDate') ? undefined : json['dataDate'],
        'locationDate': !exists(json, 'locationDate') ? undefined : json['locationDate'],
        'sinceDataDate': !exists(json, 'sinceDataDate') ? undefined : json['sinceDataDate'],
        'sinceLocationDate': !exists(json, 'sinceLocationDate') ? undefined : json['sinceLocationDate'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'speed': !exists(json, 'speed') ? undefined : json['speed'],
        'odometer': !exists(json, 'odometer') ? undefined : json['odometer'],
        'direction': !exists(json, 'direction') ? undefined : json['direction'],
        'locationType': !exists(json, 'locationType') ? undefined : json['locationType'],
        'online': !exists(json, 'online') ? undefined : json['online'],
        'blockEngineStatus': !exists(json, 'blockEngineStatus') ? undefined : json['blockEngineStatus'],
        'fleetId': !exists(json, 'fleetId') ? undefined : json['fleetId'],
        'dvrUrl': !exists(json, 'dvrUrl') ? undefined : json['dvrUrl'],
        'ignition': !exists(json, 'ignition') ? undefined : json['ignition'],
        'ignitionDate': !exists(json, 'ignitionDate') ? undefined : json['ignitionDate'],
        'imei': !exists(json, 'imei') ? undefined : json['imei'],
        'accountingCode': !exists(json, 'accountingCode') ? undefined : json['accountingCode'],
        'ttsPlate': !exists(json, 'ttsPlate') ? undefined : json['ttsPlate'],
        'idleDate': !exists(json, 'idleDate') ? undefined : json['idleDate'],
        'idleLimit': !exists(json, 'idleLimit') ? undefined : json['idleLimit'],
        'driverId': !exists(json, 'driverId') ? undefined : json['driverId'],
        'driverMsisdn': !exists(json, 'driverMsisdn') ? undefined : json['driverMsisdn'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'canDate': !exists(json, 'canDate') ? undefined : json['canDate'],
        'fuelTankLevel': !exists(json, 'fuelTankLevel') ? undefined : json['fuelTankLevel'],
        'odometerInMeters': !exists(json, 'odometerInMeters') ? undefined : json['odometerInMeters'],
        'wheelSpeed': !exists(json, 'wheelSpeed') ? undefined : json['wheelSpeed'],
        'fuelConsumedEver': !exists(json, 'fuelConsumedEver') ? undefined : json['fuelConsumedEver'],
        'handbrake': !exists(json, 'handbrake') ? undefined : json['handbrake'],
        'engineRPM': !exists(json, 'engineRPM') ? undefined : json['engineRPM'],
        'acceleratorPosition': !exists(json, 'acceleratorPosition') ? undefined : json['acceleratorPosition'],
        'engineCoolantTemperature': !exists(json, 'engineCoolantTemperature') ? undefined : json['engineCoolantTemperature'],
        'engineRuntimeEver': !exists(json, 'engineRuntimeEver') ? undefined : json['engineRuntimeEver'],
        'odometerDate': !exists(json, 'odometerDate') ? undefined : json['odometerDate'],
        'odometerValue': !exists(json, 'odometerValue') ? undefined : json['odometerValue'],
        'devicePowerDate': !exists(json, 'devicePowerDate') ? undefined : json['devicePowerDate'],
        'devicePowerValue': !exists(json, 'devicePowerValue') ? undefined : json['devicePowerValue'],
        'batteryVoltageDate': !exists(json, 'batteryVoltageDate') ? undefined : json['batteryVoltageDate'],
        'batteryVoltageValue': !exists(json, 'batteryVoltageValue') ? undefined : json['batteryVoltageValue'],
        'generatorDate': !exists(json, 'generatorDate') ? undefined : json['generatorDate'],
        'generatorValue': !exists(json, 'generatorValue') ? undefined : json['generatorValue'],
        'geoFenceId': !exists(json, 'geoFenceId') ? undefined : json['geoFenceId'],
        'geoFenceName': !exists(json, 'geoFenceName') ? undefined : json['geoFenceName'],
        'deviceModel': !exists(json, 'deviceModel') ? undefined : json['deviceModel'],
        'dayOdometer': !exists(json, 'dayOdometer') ? undefined : json['dayOdometer'],
        'redirectedServer': !exists(json, 'redirectedServer') ? undefined : json['redirectedServer'],
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'customerName': !exists(json, 'customerName') ? undefined : json['customerName'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
    };
}

export function LocationToJSON(value?: Location | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'unitId': value.unitId,
        'plate': value.plate,
        'fleetName': value.fleetName,
        'nickname': value.nickname,
        'lat': value.lat,
        'lon': value.lon,
        'driverName': value.driverName,
        'dataDate': value.dataDate,
        'locationDate': value.locationDate,
        'sinceDataDate': value.sinceDataDate,
        'sinceLocationDate': value.sinceLocationDate,
        'address': value.address,
        'speed': value.speed,
        'odometer': value.odometer,
        'direction': value.direction,
        'locationType': value.locationType,
        'online': value.online,
        'blockEngineStatus': value.blockEngineStatus,
        'fleetId': value.fleetId,
        'dvrUrl': value.dvrUrl,
        'ignition': value.ignition,
        'ignitionDate': value.ignitionDate,
        'imei': value.imei,
        'accountingCode': value.accountingCode,
        'ttsPlate': value.ttsPlate,
        'idleDate': value.idleDate,
        'idleLimit': value.idleLimit,
        'driverId': value.driverId,
        'driverMsisdn': value.driverMsisdn,
        'status': value.status,
        'canDate': value.canDate,
        'fuelTankLevel': value.fuelTankLevel,
        'odometerInMeters': value.odometerInMeters,
        'wheelSpeed': value.wheelSpeed,
        'fuelConsumedEver': value.fuelConsumedEver,
        'handbrake': value.handbrake,
        'engineRPM': value.engineRPM,
        'acceleratorPosition': value.acceleratorPosition,
        'engineCoolantTemperature': value.engineCoolantTemperature,
        'engineRuntimeEver': value.engineRuntimeEver,
        'odometerDate': value.odometerDate,
        'odometerValue': value.odometerValue,
        'devicePowerDate': value.devicePowerDate,
        'devicePowerValue': value.devicePowerValue,
        'batteryVoltageDate': value.batteryVoltageDate,
        'batteryVoltageValue': value.batteryVoltageValue,
        'generatorDate': value.generatorDate,
        'generatorValue': value.generatorValue,
        'geoFenceId': value.geoFenceId,
        'geoFenceName': value.geoFenceName,
        'deviceModel': value.deviceModel,
        'dayOdometer': value.dayOdometer,
        'redirectedServer': value.redirectedServer,
        'deviceId': value.deviceId,
        'customerName': value.customerName,
        'customerId': value.customerId,
    };
}

