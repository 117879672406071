/* tslint:disable */
/* eslint-disable */
/**
 * defaultTitle
 * defaultDescription
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface Authresponse
 */
export interface Authresponse {
    /**
     * 
     * @type {number}
     * @memberof Authresponse
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Authresponse
     */
    authTypeId?: number;
    /**
     * 
     * @type {number}
     * @memberof Authresponse
     */
    lastAccessDate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Authresponse
     */
    loginDate?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Authresponse
     */
    token?: string;
    /**
     * 
     * @type {number}
     * @memberof Authresponse
     */
    userId?: number;
}

/**
 * Check if a given object implements the Authresponse interface.
 */
export function instanceOfAuthresponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AuthresponseFromJSON(json: any): Authresponse {
    return AuthresponseFromJSONTyped(json, false);
}

export function AuthresponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): Authresponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'authTypeId': !exists(json, 'authTypeId') ? undefined : json['authTypeId'],
        'lastAccessDate': !exists(json, 'lastAccessDate') ? undefined : json['lastAccessDate'],
        'loginDate': !exists(json, 'loginDate') ? undefined : json['loginDate'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function AuthresponseToJSON(value?: Authresponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'authTypeId': value.authTypeId,
        'lastAccessDate': value.lastAccessDate,
        'loginDate': value.loginDate,
        'token': value.token,
        'userId': value.userId,
    };
}

