/* tslint:disable */
/* eslint-disable */
/**
 * defaultTitle
 * defaultDescription
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface UnitSimple
 */
export interface UnitSimple {
    /**
     * 
     * @type {number}
     * @memberof UnitSimple
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UnitSimple
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof UnitSimple
     */
    unitId?: number;
    /**
     * 
     * @type {string}
     * @memberof UnitSimple
     */
    plate?: string;
    /**
     * 
     * @type {string}
     * @memberof UnitSimple
     */
    nickname?: string;
    /**
     * 
     * @type {string}
     * @memberof UnitSimple
     */
    fleetName?: string;
    /**
     * 
     * @type {string}
     * @memberof UnitSimple
     */
    driverName?: string;
    /**
     * 
     * @type {string}
     * @memberof UnitSimple
     */
    model?: string;
    /**
     * 
     * @type {number}
     * @memberof UnitSimple
     */
    createDate?: number;
    /**
     * 
     * @type {number}
     * @memberof UnitSimple
     */
    creator?: number;
    /**
     * 
     * @type {string}
     * @memberof UnitSimple
     */
    unitMyiugo?: string;
    /**
     * 
     * @type {string}
     * @memberof UnitSimple
     */
    driverMyiugo?: string;
}

/**
 * Check if a given object implements the UnitSimple interface.
 */
export function instanceOfUnitSimple(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UnitSimpleFromJSON(json: any): UnitSimple {
    return UnitSimpleFromJSONTyped(json, false);
}

export function UnitSimpleFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnitSimple {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'unitId': !exists(json, 'unitId') ? undefined : json['unitId'],
        'plate': !exists(json, 'plate') ? undefined : json['plate'],
        'nickname': !exists(json, 'nickname') ? undefined : json['nickname'],
        'fleetName': !exists(json, 'fleetName') ? undefined : json['fleetName'],
        'driverName': !exists(json, 'driverName') ? undefined : json['driverName'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'createDate': !exists(json, 'createDate') ? undefined : json['createDate'],
        'creator': !exists(json, 'creator') ? undefined : json['creator'],
        'unitMyiugo': !exists(json, 'unitMyiugo') ? undefined : json['unitMyiugo'],
        'driverMyiugo': !exists(json, 'driverMyiugo') ? undefined : json['driverMyiugo'],
    };
}

export function UnitSimpleToJSON(value?: UnitSimple | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'unitId': value.unitId,
        'plate': value.plate,
        'nickname': value.nickname,
        'fleetName': value.fleetName,
        'driverName': value.driverName,
        'model': value.model,
        'createDate': value.createDate,
        'creator': value.creator,
        'unitMyiugo': value.unitMyiugo,
        'driverMyiugo': value.driverMyiugo,
    };
}

