/* tslint:disable */
/* eslint-disable */
/**
 * defaultTitle
 * defaultDescription
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface DriverCard
 */
export interface DriverCard {
    /**
     * 
     * @type {number}
     * @memberof DriverCard
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DriverCard
     */
    cardId?: string;
    /**
     * 
     * @type {number}
     * @memberof DriverCard
     */
    customerId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DriverCard
     */
    driverId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DriverCard
     */
    lastImei?: string;
    /**
     * 
     * @type {number}
     * @memberof DriverCard
     */
    lastReadDate?: number;
    /**
     * 
     * @type {string}
     * @memberof DriverCard
     */
    plate?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverCard
     */
    driverName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DriverCard
     */
    unitId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DriverCard
     */
    deviceId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DriverCard
     */
    createDate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DriverCard
     */
    lastUpdateDate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DriverCard
     */
    creator?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DriverCard
     */
    updater?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DriverCard
     */
    cardNo?: string | null;
}

/**
 * Check if a given object implements the DriverCard interface.
 */
export function instanceOfDriverCard(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DriverCardFromJSON(json: any): DriverCard {
    return DriverCardFromJSONTyped(json, false);
}

export function DriverCardFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverCard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'cardId': !exists(json, 'cardId') ? undefined : json['cardId'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'driverId': !exists(json, 'driverId') ? undefined : json['driverId'],
        'lastImei': !exists(json, 'lastImei') ? undefined : json['lastImei'],
        'lastReadDate': !exists(json, 'lastReadDate') ? undefined : json['lastReadDate'],
        'plate': !exists(json, 'plate') ? undefined : json['plate'],
        'driverName': !exists(json, 'driverName') ? undefined : json['driverName'],
        'unitId': !exists(json, 'unitId') ? undefined : json['unitId'],
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'createDate': !exists(json, 'createDate') ? undefined : json['createDate'],
        'lastUpdateDate': !exists(json, 'lastUpdateDate') ? undefined : json['lastUpdateDate'],
        'creator': !exists(json, 'creator') ? undefined : json['creator'],
        'updater': !exists(json, 'updater') ? undefined : json['updater'],
        'cardNo': !exists(json, 'cardNo') ? undefined : json['cardNo'],
    };
}

export function DriverCardToJSON(value?: DriverCard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'cardId': value.cardId,
        'customerId': value.customerId,
        'driverId': value.driverId,
        'lastImei': value.lastImei,
        'lastReadDate': value.lastReadDate,
        'plate': value.plate,
        'driverName': value.driverName,
        'unitId': value.unitId,
        'deviceId': value.deviceId,
        'createDate': value.createDate,
        'lastUpdateDate': value.lastUpdateDate,
        'creator': value.creator,
        'updater': value.updater,
        'cardNo': value.cardNo,
    };
}

