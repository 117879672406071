/* tslint:disable */
/* eslint-disable */
/**
 * defaultTitle
 * defaultDescription
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    msisdn?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    surname?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    customerId?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    lastAccessDate?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    createDate?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    lastUpdateDate?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    creator?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    updater?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    authTypeId?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    addressId?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    personId?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    timeZone?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    theme?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    language?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    representative?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    parentUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    fiUserToken?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    fiApiToken?: string;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'msisdn': !exists(json, 'msisdn') ? undefined : json['msisdn'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'lastAccessDate': !exists(json, 'lastAccessDate') ? undefined : json['lastAccessDate'],
        'createDate': !exists(json, 'createDate') ? undefined : json['createDate'],
        'lastUpdateDate': !exists(json, 'lastUpdateDate') ? undefined : json['lastUpdateDate'],
        'creator': !exists(json, 'creator') ? undefined : json['creator'],
        'updater': !exists(json, 'updater') ? undefined : json['updater'],
        'authTypeId': !exists(json, 'authTypeId') ? undefined : json['authTypeId'],
        'addressId': !exists(json, 'addressId') ? undefined : json['addressId'],
        'personId': !exists(json, 'personId') ? undefined : json['personId'],
        'timeZone': !exists(json, 'timeZone') ? undefined : json['timeZone'],
        'theme': !exists(json, 'theme') ? undefined : json['theme'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'representative': !exists(json, 'representative') ? undefined : json['representative'],
        'parentUserId': !exists(json, 'parentUserId') ? undefined : json['parentUserId'],
        'fiUserToken': !exists(json, 'fiUserToken') ? undefined : json['fiUserToken'],
        'fiApiToken': !exists(json, 'fiApiToken') ? undefined : json['fiApiToken'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'password': value.password,
        'msisdn': value.msisdn,
        'name': value.name,
        'surname': value.surname,
        'customerId': value.customerId,
        'lastAccessDate': value.lastAccessDate,
        'createDate': value.createDate,
        'lastUpdateDate': value.lastUpdateDate,
        'creator': value.creator,
        'updater': value.updater,
        'authTypeId': value.authTypeId,
        'addressId': value.addressId,
        'personId': value.personId,
        'timeZone': value.timeZone,
        'theme': value.theme,
        'language': value.language,
        'representative': value.representative,
        'parentUserId': value.parentUserId,
        'fiUserToken': value.fiUserToken,
        'fiApiToken': value.fiApiToken,
    };
}

