/* tslint:disable */
/* eslint-disable */
/**
 * defaultTitle
 * defaultDescription
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface LocationLog
 */
export interface LocationLog {
    /**
     * 
     * @type {number}
     * @memberof LocationLog
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LocationLog
     */
    unitId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LocationLog
     */
    lat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LocationLog
     */
    lon?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LocationLog
     */
    address?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LocationLog
     */
    dataDate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LocationLog
     */
    speed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LocationLog
     */
    direction?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof LocationLog
     */
    ignition?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof LocationLog
     */
    odometer?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LocationLog
     */
    driverId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LocationLog
     */
    locationType?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LocationLog
     */
    officialSpeedLimit?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocationLog
     */
    roadType?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LocationLog
     */
    geoFenceId?: number | null;
}

/**
 * Check if a given object implements the LocationLog interface.
 */
export function instanceOfLocationLog(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LocationLogFromJSON(json: any): LocationLog {
    return LocationLogFromJSONTyped(json, false);
}

export function LocationLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationLog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'unitId': !exists(json, 'unitId') ? undefined : json['unitId'],
        'lat': !exists(json, 'lat') ? undefined : json['lat'],
        'lon': !exists(json, 'lon') ? undefined : json['lon'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'dataDate': !exists(json, 'dataDate') ? undefined : json['dataDate'],
        'speed': !exists(json, 'speed') ? undefined : json['speed'],
        'direction': !exists(json, 'direction') ? undefined : json['direction'],
        'ignition': !exists(json, 'ignition') ? undefined : json['ignition'],
        'odometer': !exists(json, 'odometer') ? undefined : json['odometer'],
        'driverId': !exists(json, 'driverId') ? undefined : json['driverId'],
        'locationType': !exists(json, 'locationType') ? undefined : json['locationType'],
        'officialSpeedLimit': !exists(json, 'officialSpeedLimit') ? undefined : json['officialSpeedLimit'],
        'roadType': !exists(json, 'roadType') ? undefined : json['roadType'],
        'geoFenceId': !exists(json, 'geoFenceId') ? undefined : json['geoFenceId'],
    };
}

export function LocationLogToJSON(value?: LocationLog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'unitId': value.unitId,
        'lat': value.lat,
        'lon': value.lon,
        'address': value.address,
        'dataDate': value.dataDate,
        'speed': value.speed,
        'direction': value.direction,
        'ignition': value.ignition,
        'odometer': value.odometer,
        'driverId': value.driverId,
        'locationType': value.locationType,
        'officialSpeedLimit': value.officialSpeedLimit,
        'roadType': value.roadType,
        'geoFenceId': value.geoFenceId,
    };
}

