/* tslint:disable */
/* eslint-disable */
/**
 * defaultTitle
 * defaultDescription
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface Unit
 */
export interface Unit {
    /**
     * 
     * @type {number}
     * @memberof Unit
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    plate?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    nickname?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    imei?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Unit
     */
    dataDate?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    driverName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    driverId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Unit
     */
    deviceId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Unit
     */
    fleetId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    fleetName?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    shellTTSName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    model?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Unit
     */
    myiugo?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    unitWorkingHour?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Unit
     */
    odometer?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    ttsPlate?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    purchaseDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    lastUpdateDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    lastStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    controlDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Unit
     */
    unitTypeId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    driverStatus?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Unit
     */
    deviceStatus?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    driverMyiugo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    redirectedServer?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    trailerBrand?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    trailerType?: string;
    /**
     * 
     * @type {number}
     * @memberof Unit
     */
    trailerYear?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    trailerGear?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    trailerCO2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    edinmeSekli?: string;
    /**
     * 
     * @type {number}
     * @memberof Unit
     */
    edinmeTipi?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    sozlesme?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    hizmetBaslangicTarih?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    hizmetBitisTarih?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    renk?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    motorNo?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    sasiNo?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    yakitTuru?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    yakitDeposu?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    yakitSarfiyati?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    yakitTipi?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Unit
     */
    aracUstuLogo?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    driverDepartment?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Unit
     */
    gsensorDate?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    tasitCinsi?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    ozelGrup?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    customerName?: string;
    /**
     * 
     * @type {number}
     * @memberof Unit
     */
    customerStatus?: number | null;
}

/**
 * Check if a given object implements the Unit interface.
 */
export function instanceOfUnit(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UnitFromJSON(json: any): Unit {
    return UnitFromJSONTyped(json, false);
}

export function UnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): Unit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'plate': !exists(json, 'plate') ? undefined : json['plate'],
        'nickname': !exists(json, 'nickname') ? undefined : json['nickname'],
        'imei': !exists(json, 'imei') ? undefined : json['imei'],
        'dataDate': !exists(json, 'dataDate') ? undefined : json['dataDate'],
        'driverName': !exists(json, 'driverName') ? undefined : json['driverName'],
        'driverId': !exists(json, 'driverId') ? undefined : json['driverId'],
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'fleetId': !exists(json, 'fleetId') ? undefined : json['fleetId'],
        'fleetName': !exists(json, 'fleetName') ? undefined : json['fleetName'],
        'shellTTSName': !exists(json, 'shellTTSName') ? undefined : json['shellTTSName'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'myiugo': !exists(json, 'myiugo') ? undefined : json['myiugo'],
        'unitWorkingHour': !exists(json, 'unitWorkingHour') ? undefined : json['unitWorkingHour'],
        'odometer': !exists(json, 'odometer') ? undefined : json['odometer'],
        'ttsPlate': !exists(json, 'ttsPlate') ? undefined : json['ttsPlate'],
        'purchaseDate': !exists(json, 'purchaseDate') ? undefined : json['purchaseDate'],
        'lastUpdateDate': !exists(json, 'lastUpdateDate') ? undefined : json['lastUpdateDate'],
        'lastStatus': !exists(json, 'lastStatus') ? undefined : json['lastStatus'],
        'controlDate': !exists(json, 'controlDate') ? undefined : json['controlDate'],
        'unitTypeId': !exists(json, 'unitTypeId') ? undefined : json['unitTypeId'],
        'driverStatus': !exists(json, 'driverStatus') ? undefined : json['driverStatus'],
        'deviceStatus': !exists(json, 'deviceStatus') ? undefined : json['deviceStatus'],
        'driverMyiugo': !exists(json, 'driverMyiugo') ? undefined : json['driverMyiugo'],
        'redirectedServer': !exists(json, 'redirectedServer') ? undefined : json['redirectedServer'],
        'trailerBrand': !exists(json, 'trailerBrand') ? undefined : json['trailerBrand'],
        'trailerType': !exists(json, 'trailerType') ? undefined : json['trailerType'],
        'trailerYear': !exists(json, 'trailerYear') ? undefined : json['trailerYear'],
        'trailerGear': !exists(json, 'trailerGear') ? undefined : json['trailerGear'],
        'trailerCO2': !exists(json, 'trailerCO2') ? undefined : json['trailerCO2'],
        'edinmeSekli': !exists(json, 'edinmeSekli') ? undefined : json['edinmeSekli'],
        'edinmeTipi': !exists(json, 'edinmeTipi') ? undefined : json['edinmeTipi'],
        'sozlesme': !exists(json, 'sozlesme') ? undefined : json['sozlesme'],
        'hizmetBaslangicTarih': !exists(json, 'hizmetBaslangicTarih') ? undefined : json['hizmetBaslangicTarih'],
        'hizmetBitisTarih': !exists(json, 'hizmetBitisTarih') ? undefined : json['hizmetBitisTarih'],
        'renk': !exists(json, 'renk') ? undefined : json['renk'],
        'motorNo': !exists(json, 'motorNo') ? undefined : json['motorNo'],
        'sasiNo': !exists(json, 'sasiNo') ? undefined : json['sasiNo'],
        'yakitTuru': !exists(json, 'yakitTuru') ? undefined : json['yakitTuru'],
        'yakitDeposu': !exists(json, 'yakitDeposu') ? undefined : json['yakitDeposu'],
        'yakitSarfiyati': !exists(json, 'yakitSarfiyati') ? undefined : json['yakitSarfiyati'],
        'yakitTipi': !exists(json, 'yakitTipi') ? undefined : json['yakitTipi'],
        'aracUstuLogo': !exists(json, 'aracUstuLogo') ? undefined : json['aracUstuLogo'],
        'driverDepartment': !exists(json, 'driverDepartment') ? undefined : json['driverDepartment'],
        'gsensorDate': !exists(json, 'gsensorDate') ? undefined : json['gsensorDate'],
        'tasitCinsi': !exists(json, 'tasitCinsi') ? undefined : json['tasitCinsi'],
        'ozelGrup': !exists(json, 'ozelGrup') ? undefined : json['ozelGrup'],
        'customerName': !exists(json, 'customerName') ? undefined : json['customerName'],
        'customerStatus': !exists(json, 'customerStatus') ? undefined : json['customerStatus'],
    };
}

export function UnitToJSON(value?: Unit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'plate': value.plate,
        'nickname': value.nickname,
        'imei': value.imei,
        'dataDate': value.dataDate,
        'driverName': value.driverName,
        'driverId': value.driverId,
        'deviceId': value.deviceId,
        'fleetId': value.fleetId,
        'fleetName': value.fleetName,
        'shellTTSName': value.shellTTSName,
        'model': value.model,
        'myiugo': value.myiugo,
        'unitWorkingHour': value.unitWorkingHour,
        'odometer': value.odometer,
        'ttsPlate': value.ttsPlate,
        'purchaseDate': value.purchaseDate,
        'lastUpdateDate': value.lastUpdateDate,
        'lastStatus': value.lastStatus,
        'controlDate': value.controlDate,
        'unitTypeId': value.unitTypeId,
        'driverStatus': value.driverStatus,
        'deviceStatus': value.deviceStatus,
        'driverMyiugo': value.driverMyiugo,
        'redirectedServer': value.redirectedServer,
        'trailerBrand': value.trailerBrand,
        'trailerType': value.trailerType,
        'trailerYear': value.trailerYear,
        'trailerGear': value.trailerGear,
        'trailerCO2': value.trailerCO2,
        'edinmeSekli': value.edinmeSekli,
        'edinmeTipi': value.edinmeTipi,
        'sozlesme': value.sozlesme,
        'hizmetBaslangicTarih': value.hizmetBaslangicTarih,
        'hizmetBitisTarih': value.hizmetBitisTarih,
        'renk': value.renk,
        'motorNo': value.motorNo,
        'sasiNo': value.sasiNo,
        'yakitTuru': value.yakitTuru,
        'yakitDeposu': value.yakitDeposu,
        'yakitSarfiyati': value.yakitSarfiyati,
        'yakitTipi': value.yakitTipi,
        'aracUstuLogo': value.aracUstuLogo,
        'driverDepartment': value.driverDepartment,
        'gsensorDate': value.gsensorDate,
        'tasitCinsi': value.tasitCinsi,
        'ozelGrup': value.ozelGrup,
        'customerName': value.customerName,
        'customerStatus': value.customerStatus,
    };
}

