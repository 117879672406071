/* tslint:disable */
/* eslint-disable */
/**
 * defaultTitle
 * defaultDescription
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface DriverCardLog
 */
export interface DriverCardLog {
    /**
     * 
     * @type {number}
     * @memberof DriverCardLog
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DriverCardLog
     */
    cardId?: string;
    /**
     * 
     * @type {number}
     * @memberof DriverCardLog
     */
    readDate?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DriverCardLog
     */
    plate?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverCardLog
     */
    driverName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DriverCardLog
     */
    fleetName?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverCardLog
     */
    address?: string;
    /**
     * 
     * @type {number}
     * @memberof DriverCardLog
     */
    lat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DriverCardLog
     */
    lon?: number | null;
}

/**
 * Check if a given object implements the DriverCardLog interface.
 */
export function instanceOfDriverCardLog(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DriverCardLogFromJSON(json: any): DriverCardLog {
    return DriverCardLogFromJSONTyped(json, false);
}

export function DriverCardLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverCardLog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'cardId': !exists(json, 'cardId') ? undefined : json['cardId'],
        'readDate': !exists(json, 'readDate') ? undefined : json['readDate'],
        'plate': !exists(json, 'plate') ? undefined : json['plate'],
        'driverName': !exists(json, 'driverName') ? undefined : json['driverName'],
        'fleetName': !exists(json, 'fleetName') ? undefined : json['fleetName'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'lat': !exists(json, 'lat') ? undefined : json['lat'],
        'lon': !exists(json, 'lon') ? undefined : json['lon'],
    };
}

export function DriverCardLogToJSON(value?: DriverCardLog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'cardId': value.cardId,
        'readDate': value.readDate,
        'plate': value.plate,
        'driverName': value.driverName,
        'fleetName': value.fleetName,
        'address': value.address,
        'lat': value.lat,
        'lon': value.lon,
    };
}

