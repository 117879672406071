/* tslint:disable */
/* eslint-disable */
/**
 * defaultTitle
 * defaultDescription
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface Driver
 */
export interface Driver {
    /**
     * 
     * @type {number}
     * @memberof Driver
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    msisdn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    plate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    cardId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    departmentName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    cardLastReadDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Driver
     */
    status?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    departmentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    unitId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    driverCardId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Driver
     */
    myiugo?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    unitMyiugo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    driverDataDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    driverLastPlate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    cardLastPlate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    substation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    titleType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    customerName?: string;
    /**
     * 
     * @type {number}
     * @memberof Driver
     */
    customerStatus?: number | null;
}

/**
 * Check if a given object implements the Driver interface.
 */
export function instanceOfDriver(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DriverFromJSON(json: any): Driver {
    return DriverFromJSONTyped(json, false);
}

export function DriverFromJSONTyped(json: any, ignoreDiscriminator: boolean): Driver {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'msisdn': !exists(json, 'msisdn') ? undefined : json['msisdn'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'plate': !exists(json, 'plate') ? undefined : json['plate'],
        'cardId': !exists(json, 'cardId') ? undefined : json['cardId'],
        'departmentName': !exists(json, 'departmentName') ? undefined : json['departmentName'],
        'cardLastReadDate': !exists(json, 'cardLastReadDate') ? undefined : json['cardLastReadDate'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'unitId': !exists(json, 'unitId') ? undefined : json['unitId'],
        'driverCardId': !exists(json, 'driverCardId') ? undefined : json['driverCardId'],
        'myiugo': !exists(json, 'myiugo') ? undefined : json['myiugo'],
        'unitMyiugo': !exists(json, 'unitMyiugo') ? undefined : json['unitMyiugo'],
        'driverDataDate': !exists(json, 'driverDataDate') ? undefined : json['driverDataDate'],
        'driverLastPlate': !exists(json, 'driverLastPlate') ? undefined : json['driverLastPlate'],
        'cardLastPlate': !exists(json, 'cardLastPlate') ? undefined : json['cardLastPlate'],
        'substation': !exists(json, 'substation') ? undefined : json['substation'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'titleType': !exists(json, 'titleType') ? undefined : json['titleType'],
        'customerName': !exists(json, 'customerName') ? undefined : json['customerName'],
        'customerStatus': !exists(json, 'customerStatus') ? undefined : json['customerStatus'],
    };
}

export function DriverToJSON(value?: Driver | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'msisdn': value.msisdn,
        'email': value.email,
        'plate': value.plate,
        'cardId': value.cardId,
        'departmentName': value.departmentName,
        'cardLastReadDate': value.cardLastReadDate,
        'status': value.status,
        'departmentId': value.departmentId,
        'unitId': value.unitId,
        'driverCardId': value.driverCardId,
        'myiugo': value.myiugo,
        'unitMyiugo': value.unitMyiugo,
        'driverDataDate': value.driverDataDate,
        'driverLastPlate': value.driverLastPlate,
        'cardLastPlate': value.cardLastPlate,
        'substation': value.substation,
        'title': value.title,
        'titleType': value.titleType,
        'customerName': value.customerName,
        'customerStatus': value.customerStatus,
    };
}

