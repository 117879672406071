/* tslint:disable */
/* eslint-disable */
/**
 * defaultTitle
 * defaultDescription
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import dayjs from "utils/dayjs";
import { apiConfig, apiMiddleware } from "stores/ApiStore";
import type {
  Authresponse,
  Driver,
  DriverCard,
  DriverCardLog,
  Location,
  LocationLog,
  Unit,
  UnitSimple,
  User,
} from '../models';
import {
    AuthresponseFromJSON,
    AuthresponseToJSON,
    DriverFromJSON,
    DriverToJSON,
    DriverCardFromJSON,
    DriverCardToJSON,
    DriverCardLogFromJSON,
    DriverCardLogToJSON,
    LocationFromJSON,
    LocationToJSON,
    LocationLogFromJSON,
    LocationLogToJSON,
    UnitFromJSON,
    UnitToJSON,
    UnitSimpleFromJSON,
    UnitSimpleToJSON,
    UserFromJSON,
    UserToJSON,
} from '../models';

export interface AuthRequest {
    theme?: string;
    expireOldSession?: boolean;
    password?: string;
    email?: string;
}

export interface TokenDriverCardLogWebListGetRequest {
    token: string;
    firstDate?: dayjs.Dayjs;
    unitIds?: string;
    lastDate?: dayjs.Dayjs;
}

export interface TokenDriverCardWebListGetRequest {
    token: string;
}

export interface TokenDriverWebListGetRequest {
    token: string;
}

export interface TokenLocationLastMapListGetRequest {
    token: string;
}

export interface TokenLocationLogListGetRequest {
    token: string;
    firstDate?: dayjs.Dayjs;
    unitIds?: string;
    lastDate?: dayjs.Dayjs;
}

export interface TokenUnitWebListGetRequest {
    token: string;
}

export interface TokenUserListGetRequest {
    token: string;
}

export interface TokenUserUnitRelationListGetRequest {
    token: string;
    userId?: string;
}

export interface TokenUserUpdatePostRequest {
    token: string;
    id?: string;
    fiUserToken?: string;
}

/**
 * DefaultApi - interface
 * 
 * @export
 * @interface DefaultApiInterface
 */
export interface DefaultApiInterface {
    /**
     * 
     * @param {string} [theme] 
     * @param {boolean} [expireOldSession] 
     * @param {string} [password] 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    authRaw(requestParameters: AuthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Authresponse>>;

    /**
     */
    auth(requestParameters: AuthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Authresponse>;

    /**
     * Auto generated using Swagger Inspector
     * @param {string} token token
     * @param {Date} [firstDate] 
     * @param {string} [unitIds] 
     * @param {Date} [lastDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    tokenDriverCardLogWebListGetRaw(requestParameters: TokenDriverCardLogWebListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DriverCardLog>>>;

    /**
     * Auto generated using Swagger Inspector
     */
    tokenDriverCardLogWebListGet(requestParameters: TokenDriverCardLogWebListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DriverCardLog>>;

    /**
     * Auto generated using Swagger Inspector
     * @param {string} token token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    tokenDriverCardWebListGetRaw(requestParameters: TokenDriverCardWebListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DriverCard>>>;

    /**
     * Auto generated using Swagger Inspector
     */
    tokenDriverCardWebListGet(requestParameters: TokenDriverCardWebListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DriverCard>>;

    /**
     * Auto generated using Swagger Inspector
     * @param {string} token token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    tokenDriverWebListGetRaw(requestParameters: TokenDriverWebListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Driver>>>;

    /**
     * Auto generated using Swagger Inspector
     */
    tokenDriverWebListGet(requestParameters: TokenDriverWebListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Driver>>;

    /**
     * Auto generated using Swagger Inspector
     * @param {string} token token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    tokenLocationLastMapListGetRaw(requestParameters: TokenLocationLastMapListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Location>>>;

    /**
     * Auto generated using Swagger Inspector
     */
    tokenLocationLastMapListGet(requestParameters: TokenLocationLastMapListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Location>>;

    /**
     * Auto generated using Swagger Inspector
     * @param {string} token token
     * @param {Date} [firstDate] 
     * @param {string} [unitIds] 
     * @param {Date} [lastDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    tokenLocationLogListGetRaw(requestParameters: TokenLocationLogListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LocationLog>>>;

    /**
     * Auto generated using Swagger Inspector
     */
    tokenLocationLogListGet(requestParameters: TokenLocationLogListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LocationLog>>;

    /**
     * Auto generated using Swagger Inspector
     * @param {string} token token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    tokenUnitWebListGetRaw(requestParameters: TokenUnitWebListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Unit>>>;

    /**
     * Auto generated using Swagger Inspector
     */
    tokenUnitWebListGet(requestParameters: TokenUnitWebListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Unit>>;

    /**
     * Auto generated using Swagger Inspector
     * @param {string} token token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    tokenUserListGetRaw(requestParameters: TokenUserListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<User>>>;

    /**
     * Auto generated using Swagger Inspector
     */
    tokenUserListGet(requestParameters: TokenUserListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<User>>;

    /**
     * Auto generated using Swagger Inspector
     * @param {string} token token
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    tokenUserUnitRelationListGetRaw(requestParameters: TokenUserUnitRelationListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UnitSimple>>>;

    /**
     * Auto generated using Swagger Inspector
     */
    tokenUserUnitRelationListGet(requestParameters: TokenUserUnitRelationListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UnitSimple>>;

    /**
     * Auto generated using Swagger Inspector
     * @param {string} token token
     * @param {string} [id] 
     * @param {string} [fiUserToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    tokenUserUpdatePostRaw(requestParameters: TokenUserUpdatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>>;

    /**
     * Auto generated using Swagger Inspector
     */
    tokenUserUpdatePost(requestParameters: TokenUserUpdatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User>;

}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI implements DefaultApiInterface {

    /**
     */
    async authRaw(requestParameters: AuthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Authresponse>> {
        const queryParameters: any = {};

        if (requestParameters.theme !== undefined) {
            queryParameters['theme'] = requestParameters.theme;
        }

        if (requestParameters.expireOldSession !== undefined) {
            queryParameters['expireOldSession'] = requestParameters.expireOldSession;
        }

        if (requestParameters.password !== undefined) {
            queryParameters['password'] = requestParameters.password;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthresponseFromJSON(jsonValue));
    }

    /**
     */
    async auth(requestParameters: AuthRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Authresponse> {
        const response = await this.authRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Auto generated using Swagger Inspector
     */
    async tokenDriverCardLogWebListGetRaw(requestParameters: TokenDriverCardLogWebListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DriverCardLog>>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling tokenDriverCardLogWebListGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.firstDate !== undefined) {
            queryParameters['firstDate'] = (requestParameters.firstDate as any).toISOString();
        }

        if (requestParameters.unitIds !== undefined) {
            queryParameters['unitIds'] = requestParameters.unitIds;
        }

        if (requestParameters.lastDate !== undefined) {
            queryParameters['lastDate'] = (requestParameters.lastDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{token}/driver-card-log/web/list`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverCardLogFromJSON));
    }

    /**
     * Auto generated using Swagger Inspector
     */
    async tokenDriverCardLogWebListGet(requestParameters: TokenDriverCardLogWebListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DriverCardLog>> {
        const response = await this.tokenDriverCardLogWebListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Auto generated using Swagger Inspector
     */
    async tokenDriverCardWebListGetRaw(requestParameters: TokenDriverCardWebListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DriverCard>>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling tokenDriverCardWebListGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{token}/driver-card/web/list`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverCardFromJSON));
    }

    /**
     * Auto generated using Swagger Inspector
     */
    async tokenDriverCardWebListGet(requestParameters: TokenDriverCardWebListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DriverCard>> {
        const response = await this.tokenDriverCardWebListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Auto generated using Swagger Inspector
     */
    async tokenDriverWebListGetRaw(requestParameters: TokenDriverWebListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Driver>>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling tokenDriverWebListGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{token}/driver/web/list`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverFromJSON));
    }

    /**
     * Auto generated using Swagger Inspector
     */
    async tokenDriverWebListGet(requestParameters: TokenDriverWebListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Driver>> {
        const response = await this.tokenDriverWebListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Auto generated using Swagger Inspector
     */
    async tokenLocationLastMapListGetRaw(requestParameters: TokenLocationLastMapListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Location>>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling tokenLocationLastMapListGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{token}/locationLast/map/list`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LocationFromJSON));
    }

    /**
     * Auto generated using Swagger Inspector
     */
    async tokenLocationLastMapListGet(requestParameters: TokenLocationLastMapListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Location>> {
        const response = await this.tokenLocationLastMapListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Auto generated using Swagger Inspector
     */
    async tokenLocationLogListGetRaw(requestParameters: TokenLocationLogListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LocationLog>>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling tokenLocationLogListGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.firstDate !== undefined) {
            queryParameters['firstDate'] = (requestParameters.firstDate as any).toISOString();
        }

        if (requestParameters.unitIds !== undefined) {
            queryParameters['unitIds'] = requestParameters.unitIds;
        }

        if (requestParameters.lastDate !== undefined) {
            queryParameters['lastDate'] = (requestParameters.lastDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{token}/locationLog/list`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LocationLogFromJSON));
    }

    /**
     * Auto generated using Swagger Inspector
     */
    async tokenLocationLogListGet(requestParameters: TokenLocationLogListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LocationLog>> {
        const response = await this.tokenLocationLogListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Auto generated using Swagger Inspector
     */
    async tokenUnitWebListGetRaw(requestParameters: TokenUnitWebListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Unit>>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling tokenUnitWebListGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{token}/unit/web/list`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UnitFromJSON));
    }

    /**
     * Auto generated using Swagger Inspector
     */
    async tokenUnitWebListGet(requestParameters: TokenUnitWebListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Unit>> {
        const response = await this.tokenUnitWebListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Auto generated using Swagger Inspector
     */
    async tokenUserListGetRaw(requestParameters: TokenUserListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<User>>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling tokenUserListGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{token}/user/list`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     * Auto generated using Swagger Inspector
     */
    async tokenUserListGet(requestParameters: TokenUserListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<User>> {
        const response = await this.tokenUserListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Auto generated using Swagger Inspector
     */
    async tokenUserUnitRelationListGetRaw(requestParameters: TokenUserUnitRelationListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UnitSimple>>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling tokenUserUnitRelationListGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{token}/user-unit-relation/list`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UnitSimpleFromJSON));
    }

    /**
     * Auto generated using Swagger Inspector
     */
    async tokenUserUnitRelationListGet(requestParameters: TokenUserUnitRelationListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UnitSimple>> {
        const response = await this.tokenUserUnitRelationListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Auto generated using Swagger Inspector
     */
    async tokenUserUpdatePostRaw(requestParameters: TokenUserUpdatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling tokenUserUpdatePost.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.fiUserToken !== undefined) {
            queryParameters['fiUserToken'] = requestParameters.fiUserToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{token}/user/update`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Auto generated using Swagger Inspector
     */
    async tokenUserUpdatePost(requestParameters: TokenUserUpdatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.tokenUserUpdatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}


export function useDefaultApi(withMiddleware = true) {
    if(withMiddleware)
        //@ts-ignore
        return new DefaultApi(apiConfig["defaultTitle"]).withMiddleware(apiMiddleware)
    else
        return new DefaultApi(apiConfig["defaultTitle"])
}
